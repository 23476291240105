<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">cta: large</div>
        <div class="module-preview--contents">
            <div>
                <div class="font-600 text-lg">{{ getValue('ctaTitle') }}</div>
                <div class="font-500 text-base">{{ getValue('ctaSubtitle') }}</div>    
            </div>
            <div class="self-center">
                <span class="btn-primary btn-solid">{{ getValue('buttonText') }}</span>
            </div>            
        </div>
    </div>     
</template>

<script>
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
    }
</script>

<style scoped>
    .module-preview--contents{
        grid-template-columns: 1fr auto;        
    }
</style>