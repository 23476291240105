<template>
    <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">Text block with image</div>
        <div class="bg-gray-200 flex mb-8 px-4">
            <div class="pt-4 pb-4">
                <img :src="getValue('image')" class="h-48" />
            </div>
            <div class="w-1/2 p-4" :class="alignmentClass">
                <h2 class="text-2xl text-black block font-bold">{{ getValue('headline')}}</h2>
                <h4 class="text-lg text-gray-700 block font-bold">{{ getValue('subHeadline')}}</h4>
                <span class="mt-4 pb-8 block" v-html="getValue('content')"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        computed: {
            alignmentClass() {
                return 'text-' + this.getValue('alignment');
            }
        }
    }
</script>