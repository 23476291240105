<template>
    <div v-if="page" class="relative">
        <div class="bg-highlight-100 p-4 mb-0 border border-highlight-300 w-full">
            <div class="pr-8 flex justify-between items-center">
                <div class="text-xs text-center">
                    <p class="label text-highlight-textSmall">title</p>
                    {{ page.website_pages_name }}
                </div>
                <div class="text-xs text-center">
                    <p class="label text-highlight-textSmall">slug</p>
                    /{{ page.url_alias }}
                </div>
                <div class="text-center text-xs">
                    <p class="label text-highlight-textSmall">set as homepage</p>
                    <icon name="checkmark" class="h-4 w-4 text-green-600 mt-1 inline" v-if="page.is_home_page"></icon>
                    <icon name="close" class="h-4 w-4 text-red-600 mt-1 inline" v-else></icon>
                </div>
                <div class="text-center text-xs">
                    <p class="label text-highlight-textSmall">show in navigation</p>
                    <icon name="checkmark" class="h-4 w-4 text-green-600 mt-1 inline" v-if="page.show_in_nav"></icon>
                    <icon name="close" class="h-4 w-4 text-red-600 mt-1 inline" v-else></icon>
                </div>
                <div class="text-center text-xs">
                    <p class="label text-highlight-textSmall">published</p>
                    <icon name="checkmark" class="h-4 w-4 text-green-600 mt-1 inline" v-if="page.is_published"></icon>
                    <icon name="close" class="h-4 w-4 text-red-600 mt-1 inline" v-else></icon>
                </div>
            </div>

        </div>
        <div v-if="pages_widgets.length === 0">
            <h3 class="section-title">add module</h3>
            <div class="bg-gray-50 py-4 px-2 mb-6 border-gray-300 border">
                <div class="p-4 text-center cursor-pointer">
                    <modal-link
                        v-if="page"
                        route="modules.index"
                        :params="{websiteId: page.websites_id, pageSlug: page.url_alias }"
                    >
                        <icon name="module" class="h-24 w-24 inline"></icon>
                        <div class="text-base font-medium font-inter text-gray-400 mt-2">add new widget</div>
                    </modal-link>
                </div>
            </div>
        </div>
        <div v-if="pages_widgets.length > 0" class="mt-2">
            <h3 class="section-title flex items-center justify-between py-2 bg-white add-wrapper">
                current modules
                <modal-link
                    v-if="page"
                    route="modules.index"
                    :params="{websiteId: page.websites_id, pageSlug: page.url_alias }"
                >
                    <div class="text-sm font-400 font-frank text-highlight-textSmall underline">add new</div>
                </modal-link>
            </h3>
            <div>
                <div class="cursor-pointer">
                    <draggable
                        v-if="pages_widgets"
                        :list="pages_widgets"
                        item-key="id"
                        @change="updatePageWidgetOrder"
                        handle=".sortable-handle"
                    >
                        <template v-slot:item="{element: page_widget}">
                        <div class="sortable-handle">
                            <!-- Widget preview components are dynamically registered in main.js -->
                            <modal-link route="widgets.edit" :params="{ websiteId: page.websites_id, pageSlug: page.url_alias, pageWidgetId: page_widget.id }" >
                                <component :is="previewComponent(page_widget)" :values="page_widget.values" v-if="page_widget"></component>
                            </modal-link>
                        </div>
                        </template>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Auth from "@/mixins/Auth";
    import draggable from 'vuedraggable';
    import Icon from "@/components/ui/Icon";
    import EventBus from "@/utils/EventBus";
    import {mapActions, mapGetters} from "vuex";
    import NotifyMixin from "@/mixins/NotifyMixin";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalLink from "@/components/ui/modals/ModalLink";

    export default {
        components: { Icon, ModalLink, draggable },
        mixins: [ ModalNavigation, Auth, NotifyMixin],
        data: () => {
            return {
                websiteId: null,
                page: null,
                pages_widgets: [],
            }
        },
        computed: {
            ...mapGetters({
                getPageBySlugAndWebsiteId: 'pages/getPageBySlugAndWebsiteId',
                getPagesWidgetsByPageNameAndWebsiteId: 'pages_widgets/getPagesWidgetsByPageNameAndWebsiteId',
                getWidgetById: 'widgets/getWidgetById'
            }),
        },
        methods: {
            ...mapActions({
                loadCustomerPagesWidgetsInStore: 'pages_widgets/loadPagesWidgets',
                updatePageWidgetOrderInStore: 'pages_widgets/updatePageWidgetOrder',
                addAction: 'actions/addAction',
            }),
            updatePageWidgetOrder() {
                let data = [];
                this.pages_widgets.forEach( (page_widget, index) => {
                    data.push({order_on_page: index + 1, id: page_widget.id});
                    this.updatePageWidgetOrderInStore({ id: page_widget.id, order_on_page: index + 1});
                });

                this.$cmsDataProvider.create('reorderWidgets', {
                    websiteId: this.websiteId,
                    pageName: this.page.website_pages_name,
                    data: {
                        page_widgets: data
                    }
                }).then( () => {
                    this.notifySuccess('the module order was updated successfully');
                }).catch( () => {
                    this.notifyError('there was an error saving the widget order');
                });
            },
            fetchCustomerPagesWidgets() {
                this.$cmsDataProvider.get('customerPagesWidgets').then(response => {
                    this.loadCustomerPagesWidgetsInStore(response);
                    this.pages_widgets = this.getPagesWidgetsByPageNameAndWebsiteId(this.page.website_pages_name, this.$route.params.websiteId);
                    // eager load the widget
                    this.pages_widgets.forEach(page_widget => {
                        page_widget['widget'] = this.getWidgetById(page_widget.widget_id);
                    });
                })
                .catch((err) => this.notifyError(err));
            },
            previewComponent(page_widget) {
                return require('@/components/cms/widgets/previews/' + page_widget.widget.component + 'Preview').default;
            },
            deletePage() {
                this.$cmsDataProvider.delete('deletePage', { websiteId: this.page.websites_id, id: this.page.website_pages_name })
                    .then( () => {
                        this.notifySuccess('The page was removed successfully');
                        this.$router.replace({name: 'websites.show', params: {id: this.page.websites_id, direction: 'back'}})
                    });
            },
        },
        created() {
            this.websiteId = this.$route.params.websiteId;
            this.page = this.getPageBySlugAndWebsiteId(this.$route.params.pageSlug, this.websiteId);
        },
        mounted() {
            this.fetchCustomerPagesWidgets();
            this.setActiveModalTitle(this.page.website_pages_name.toLowerCase() + ' page modules');

            this.addAction({
                routeName: this.$route.name,
                item: {
                    id: 'cms-delete-page',
                    title: "Delete Page",
                    showConfirmation: true,
                    confirmationMessage: "Are you sure you want to delete this page?",
                    confirmBtnText: 'yes, delete',
                    confirmationType: 'warning'
                }
            });

            EventBus.on('confirm-cms-delete-page', () => {
                this.deletePage();
            })
        },
         unmounted() {
            EventBus.off('confirm-cms-delete-page');
        }
    }
</script>
<style>
.add-wrapper{
    top: 47px;
    z-index: 1;
}

.sortable-handle + .sortable-handle{
    margin-top: 2rem;
}
</style>
